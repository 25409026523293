<template>
  <div v-if="item != null" v-html="item.content"></div>
</template>

<script>
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();

export default {
  components: {},

  data: () => ({
    loading: false,

    item: null,
  }),

  mounted: function () {
    this.getItem();
  },

  methods: {
    getItem() {
      this.loading = true;
      apiService.getFlatPage(this.$route.params.id).then((response) => {
        this.item = response;
        this.loading = false;
      });
    },
  },
};
</script>
